
.scoreBar {
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    -webkit-transition: 0.15s linear;
    -moz-transition: 0.15s linear;
    -o-transition: 0.15s linear;
    transition: 0.15s linear;
    -webkit-transition-property: width, background-color;
    -moz-transition-property: width, background-color;
    -o-transition-property: width, background-color;
    transition-property: width, background-color;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25), inset 0 2px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25), inset 0 2px rgba(255, 255, 255, 0.1);
}

.scoreTxt {
    font-size: 20px;
}