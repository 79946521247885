
#logos {
    background-color: rgb(255, 255, 255, 0.85);
    border-radius: 12px; 
    border: 2px solid black;
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 5px;
    margin-bottom: 20px;
}

#welcomeSite {
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 12px;
    margin: 5px;
    padding: 5px;
}

.siteicon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid black;
    text-align: center;
    vertical-align: middle;
    margin: 5px;
    padding: 5px;
}

.company {
    font-size: 45px;
    text-align: center;
    vertical-align: middle;
    font-weight: 700;
    margin: 5px;
    padding: 5px;
}
  
.diaryGo {
    font-size: 15px; 
    background-color: turquoise;
    border-radius: 12px; 
    border: 2px solid black;
    padding: 10px;
}
  
.diaryGo:hover {
    font-size: 15px; 
    background-color: darkturquoise;
    border-radius: 12px; 
    border: 2px solid black;
    padding: 10px;
}
  
.diaryGo:active {
    font-size: 15px; 
    background-color: rgb(64, 240, 243);
    border-radius: 12px; 
    border: 2px solid black;
    padding: 10px;
}

.gamesGo {
    font-size: 15px; 
    background-color: rgb(224, 184, 64);
    border-radius: 12px; 
    border: 2px solid black;
    padding: 10px;
}
  
.gamesGo:hover {
    font-size: 15px; 
    background-color: rgb(209, 167, 0);
    border-radius: 12px; 
    border: 2px solid black;
    padding: 10px;
}
  
.gamesGo:active {
    font-size: 15px; 
    background-color: rgb(243, 177, 64);
    border-radius: 12px; 
    border: 2px solid black;
    padding: 10px;
}