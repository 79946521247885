
.gamerow {
    text-align: center;
    vertical-align: middle;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-collapse: collapse;
    border-radius: 20px;
    background-color: rgb(255, 255, 255, 0.5);
    margin: 5px;
    padding: 5px;
  }
