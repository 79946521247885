@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

.diaryGraphsPage {
    margin: 5px;
    padding: 5px;
}

#diaryGraph1 {
    width: 100%;
    height: 500px;
    background-color: aliceblue;
}