@import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

.diaryMonthsPage {
    margin: 5px;
    padding: 5px;
}

.diaryMonthsTable {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.diaryMonthsColumn1 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 4%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn2 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 22%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn3 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 16%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn4 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 8%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn5 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 18%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn6 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 9%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn7 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 11%;
    height: 90%;
    padding-top: 3px;
}

.diaryMonthsColumn8 {
    display: block;
    flex-direction: row;
    border-right: 2px black solid;
    text-align: center;
    width: 12%;
    height: 90%;
    padding-top: -9px;
    font-family: 'Agbalumo';
    font-size: 19px;
}

.diaryMonthsColumn9 {
    display: block;
    flex-direction: column;
    text-align: center;
    border-right: 0px rgba(0, 0, 0, 0.84) solid;
    width: 12%;
    height: 90%;
    border-radius: 0px 10px 10px 0px;
    padding-top: -9px;
    font-family: 'IBM Plex Sans';
    font-size: 20px;
    font-weight: 600;
}

