@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

.diaryMonthsRow {
    display: flex;
    align-items: center;
    height: 35px;
    background-color: rgb(255, 255, 255, 1);
    border-radius: 12px; 
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    border-left: 3px black solid;
    border-right: 3px black solid;
}

.monthScoreBar {
    position: relative;
    background-color: rgba(100,100,255,0.6);
    height: 30px;
    bottom: 3px;
}

.monthScore {
    position: relative;
    margin-top: -33px;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 18px;
}

.worst {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  color: darkred;
  font-size: 18px;
}

.best {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  color: darkgreen;
  font-size: 18px;
}

.diaryMonthsColumn2 .worst {
  margin-top: -2px;
}

.diaryMonthsColumn2 .best {
  margin-top: -2px;
}


.shining {
    z-index:0;
}


  /* Shine */
.shining:after {
      content:'';
      height:30px;
      width:6%;
      position: absolute;
      z-index:1;
      animation: slide 2s infinite;
       
    /* 
    CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
    */
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
  }

  @keyframes slide {
    0% {transform:translateX(-100%);}
    50% {transform:translateX(-10%);}
    100% {transform:translateX(-100%);}
}