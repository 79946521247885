
.dayForm {
    background-color: aliceblue;
    text-align: center;
    margin: 20px;
    padding: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 40px;
}


.formContainer {
    
    background-color: rgb(228, 250, 255);
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    border: 3px solid dodgerblue;
    margin-top: 15px;
    border-radius: 25px;
}

.formContainer #inputForm {
    
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 80%;
    padding: 10px;
    border: 2px solid darkblue;
    margin-bottom: 6px;
    border-radius: 15px;
}

.formContainer button {
    
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 30%;
    padding: 10px;
    border: 3px solid darkred;
    background-color: lightcoral;
    margin-top: 15px;
    border-radius: 15px;
}

.formContainer ErrorMessage {
    color: darkred;
}

.dayFormDate {
    font-weight: 600;
    font-family: 'IBM Plex Sans';
    font-size: 25px;
}