.diaryTypeButton {
    font-size: 17px; 
    background-color: turquoise;
    border-radius: 12px; 
    border: 2px solid black;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
}
  
.diaryTypeButton:hover {
    font-size: 17px; 
    background-color: darkturquoise;
    border-radius: 12px; 
    border: 2px solid black;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
}
  
.diaryTypeButton:active {
    font-size: 17px; 
    background-color: rgb(64, 240, 243);
    border-radius: 12px; 
    border: 2px solid black;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 15px;
}