
.gp_gameblock {
  display: flex;
  align-items: stretch;
  border: 2px solid black;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 1%;
  border-radius: 20px;
  background-color: rgb(255, 255, 255, 0.8);
  width: 98%;

  -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25), inset 0 4px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25), inset 0 4px rgba(255, 255, 255, 0.1);
}


.gameicon {
  width: 100px;
  height: 100px;
  border-radius: 30%;
  border: 3px solid black;
  flex-direction: column;
  text-align: center;
  vertical-align: middle;

  -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25), inset 0 4px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25), inset 0 4px rgba(255, 255, 255, 0.1);
}

.gameblock2 {
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 20px;
}

.gamename {
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  background-color: rgb(255, 255, 255, 0.6);
  border-radius: 12px;
  border: 2px solid black;
}

.gamedesc {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 12px;
}

.rdate {
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 12px;
  text-decoration: underline;
}

iframe.fullScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

