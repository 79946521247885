
.diaryPage {
    margin: 5px;
    padding: 5px;
}

.moveBtn {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(246, 175, 45, 0.729);
    border-radius: 20px;
    font-weight: 500;
    font-family: 'IBM Plex Sans';
    font-size: 15px;
    -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25), inset 0 3px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25), inset 0 3px rgba(255, 255, 255, 0.1);
}
