

.diaryFooter {
    height: 15%;
    background-color: rgb(255, 255, 255, 0.85);
    border-radius: 12px; 
    border: 2px solid black;
    display: flex;
    align-items: center;
    margin: 7px;
    padding: 7px;
    bottom: 7px;
    position: sticky;
}
