

#demosText {
    font-size: 25px; 
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 12px; 
    border: 2px solid black;
    margin: 5px;
    padding: 5px;
}

#miniGamesText {
    font-size: 25px; 
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 12px; 
    border: 2px solid black;
    margin: 5px;
    padding: 5px;
}

.gamesPage {
  margin: 5px;
  padding: 5px;
}